body {
  margin: 0;
  background: #EDF0F2;
}

.post-editor-wrapper {
  padding: 0.6rem;
  display: flex;
  flex-direction: column;
}

.post-editor {
  background-color: white;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  border: 1px solid lightgray;
  padding: 0 0.5rem;
  font-family: 'Works Sans', sans-serif;
  border-top-width: 0;
  flex: 0 0 400px;
}

.post-editor-toolbar {
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
  border: 1px solid lightgray !important;
  margin-bottom: 0 !important;
}